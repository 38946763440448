import React from 'react';
import {ButtonGroup, Dropdown, IconSettings} from "@salesforce/design-system-react";

import downloadtemplate from '../AgentTargetProspectingTemplate.csv';
import {useHistory} from "react-router";

const targetValues = [
    {label: 'Young Childfree', value: 'Young Childfree', id:'Young Childfree'},
    {label: 'Young Families', value: 'Young Families', id:'Young Families'},
    {label: 'Mid Career Families', value: 'Mid Career Families', id:'Mid Career Families'},
    {label: 'Affluent Mid Career Families', value: 'Affluent Mid Career Families', id:'Affluent Mid Career Families'},
    {label: 'Affluent Pre Retirees', value: 'Affluent Pre Retirees', id:'Affluent Pre Retirees'},
    {label: 'Affluent Retirees', value: 'Affluent Retirees', id:'Affluent Retirees'},
    {label: 'Small Business', value: 'Small Business', id:'Small Business'},
]

function TargetSegementSelect(props){
    const history=useHistory();
    return (
        <div class="slds-p-top_medium slds-p-left_medium slds-p-bottom_xx-large">
          <IconSettings iconPath="/icons">
            <ButtonGroup variant="list">
                {/*eslint-disable-next-line*/}
                <Dropdown
                    align="center"
                    iconCategory="utility"
                    iconName="down"
                    containerClassName ="dropdowncss"
                    width="x-small"
                    iconPosition="right"
                    label="Bulk Upload Consumers"
                    onSelect={(item) =>{
                        if(item.value === 'Young Childfree'){
                            console.log('ShubhValue'+item.value );
                        }
                        else if(item.value === 'upload'){
                            history.push("/upload");
                        }
                    }}
                    options={targetValues}
                />
                </ButtonGroup>
            </IconSettings>
        </div>
    );

}

export {TargetSegementSelect};
