import React, {useState, useEffect} from 'react';
import {Button, Input, Combobox, IconSettings} from "@salesforce/design-system-react";
import {genHashMap, selectMatchingHashes} from "./nameSlice";
import {selectAllNests} from "../nests/nestSlice";
import {useSelector, useDispatch} from "react-redux";
import { ToastMessages } from './ToastMessages';
import {generateSaveNameThunk} from "./nameSlice";
import {useHistory} from "react-router";
import AddressSection from "./AddressSection";
import {TargetSegementSelect} from "./TargetSegementSelect";


function NamesSidePanel(props){
    console.log('NamesSidePanel/setAddAddress  props',props);
    const dispatch = useDispatch();
    const hashMatches = useSelector(selectMatchingHashes(props.nameToEdit.id));

    console.log('NamesSidePanel name nameToEdit',props.nameToEdit);


    const [name,setName] = useState('');
    const [id,setId] = useState('');
    const [nestId,setNestId] = useState('');
    const [isNewName,setIsNewName] = useState(false);
    const [firstName,setFirstName] = useState('');
    const [lastName,setLastName] = useState('');
    const [phone,setPhone] = useState('');
    const [email,setEmail] = useState('');
    
    const [street,setStreet] = useState('');
    const [city,setCity] = useState('');
    const [adState,setAdState] = useState('');
    const [zip,setZip] = useState('');
    const [addAddress, setAddAddress] = useState(false);

    const [phoneErrorText, setPhoneErrorText] = useState('');
    const [lastNameErrorText, setLastNameErrorText] = useState('');
    const [firstNameErrorText, setFirstNameErrorText] = useState('');
    const isValidFN = firstNameErrorText==='' && firstName!=='';
    const isValidLN = lastNameErrorText==='' && lastName!=='';
    const isValidPhone = phoneErrorText==='' && phone!=='';
    const [emailErrorText, setEmailErrorText] = useState('');
    const isValidEmail = emailErrorText === '' && email!=='';
    const [isValidZip, setZipValid] = useState(false);
    const [isValidStreet, setStreetValid] = useState(false);
    const [isValidState , setStateValid] = useState(false);
    const [isValidCityLength, setCityValidLength] = useState(false);
    const [isValidCitySpec, setCityValidSpec] = useState(false);
    const [errorType, setErrorType] = useState('');

    useEffect(()=>{
        console.log('props---',props.nameToEdit);
            setName(props.nameToEdit);
            setId(props.nameToEdit.id);
            setNestId(props.nameToEdit.nest_id);
            setIsNewName(props.nameToEdit.is_new);
            setFirstName(props.nameToEdit.first_name);
            setLastName(props.nameToEdit.last_name);
            setPhone(props.nameToEdit.phone);
            setEmail(props.nameToEdit.email);
            setStreet(props.nameToEdit.street);
            setCity(props.nameToEdit.city);
            setAdState(props.nameToEdit.state);
            setZip(props.nameToEdit.zip);

        if( (props.nameToEdit.street!==undefined && props.nameToEdit.street.trim()!=='') || (props.nameToEdit.zip!==undefined && props.nameToEdit.zip.trim()!=='')){
            console.log('NamesSidePanel/setAddAddress have street address setting address visible');
            setAddAddress(true);
        }
        else{
            console.log('NamesSidePanel/setAddAddress now address ',street,zip);
        }
        if(props.nameToEdit.nest_id==='' || props.nameToEdit.nest_id === undefined){
            console.log('NamesSidePanel/resetSelectedNest no nest id from record');
            if(props.selectedNest && props.selectedNest!==''){
                console.log('NamesSidePanel/resetSelectedNest nest provided in props');
                setNestId(props.selectedNest.id);
            }
            else{
                console.log('NamesSidePanel/resetSelectedNest no nest provided in props, resetting');
                //clear on cancel
                setNestId('');
            }
        }
        
        if(props.nameToEdit.nest_id){
            //Phone validation on selection of row
            validatePhoneInput(props.nameToEdit.phone);

            //email validation on selection of row
            validateEmailInput(props.nameToEdit.email);

            //First Name validation on selection of row
            validateFirstNameInput(props.nameToEdit.first_name);

            //Last Name validation on selection of row
            validateLastNameInput(props.nameToEdit.last_name);
        }

    },[props.nameToEdit, isNewName])

    useEffect(()=>{
        if(props.nameToEdit.nest_id){
            //Phone validation on selection of row
            validatePhoneInput(phone);

            //email validation on selection of row
            validateEmailInput(email);

            //First Name validation on selection of row
            //validateFirstNameInput(props.nameToEdit.first_name);

            //Last Name validation on selection of row
            //validateLastNameInput(props.nameToEdit.last_name);
        }

    },[phone, email])

    console.log('NamesSidePanel name value:',name);
    function getValues()  {
        return {
            id: id,
            nest_id: nestId,
            first_name: firstName, last_name: lastName,
            phone: phone,email: email,
            street: street, city: city,state: adState, zip: zip
        }
    }
    function dublicateCheck()  {
        return {
            id: id,
            first_name: firstName, last_name: lastName,
            phone: phone,email: email,
            street: street, city: city,state: adState, zip: zip
        }
    }

    


    //editNameId
    const buttonLabel = ("is_new" in props.nameToEdit && props.nameToEdit.is_new)?'Add':'Save';

    const validateFirstNameInput =(firstName) => {
        setFirstNameErrorText('');
        if(firstName && firstName!=='' && firstName.length > 40){
            setFirstNameErrorText('First Name max limit is 40 characters');
        }else if(!firstName){
            setFirstNameErrorText('First Name is a required field');
        } else {
            setFirstNameErrorText('');
        }
    }
    
    const validateLastNameInput =(lastName) => {
        setLastNameErrorText('');
        if(lastName && lastName!=='' && lastName.length > 80){
            setLastNameErrorText('Last Name max limit is 80 characters');
        } else if(!lastName){
            setLastNameErrorText('Last Name is a required field');
        } else {
            setLastNameErrorText('');
        }
    }

    const validatePhoneInput = (phoneNumber) => {
        const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
        const alphaRegex = /[^a-zA-Z]/;
        //Check if manual name creation
        if(isNewName){
            if(phoneNumber && phoneNumber!==''){
                if(!email && email === ''){
                    setEmailErrorText('');
                }
                if(!alphaRegex.exec(phoneNumber)){
                    setPhoneErrorText("Phone must be 10 digits");
                    return; 
                }
                if(phoneNumber.match(/\d/g)){
                    if(phoneNumber.match(/\d/g).length!==10){
                        setPhoneErrorText("Phone must be 10 digits");
                        return;
                    }
                    else if(!phoneRegex.exec(phoneNumber)){
                        setPhoneErrorText("Phone must be 10 digits");
                    }
                    else{
                        setPhoneErrorText('')
                    }
                }else{
                    setPhoneErrorText("Phone must be 10 digits");
                }
                
            }else if(!phoneNumber && phoneNumber === '' && !email && email === ''){
                setPhoneErrorText('Phone or Email is required')
            }else{
                setPhoneErrorText('')
            }
        }else{//Selected existing name
            if(phoneNumber && phoneNumber!==''){
                
                if(!alphaRegex.exec(phoneNumber)){
                    //console.log('1');
                    setPhoneErrorText("Phone must be 10 digits");
                    return; 
                }
                if(phoneNumber.match(/\d/g)){
                    if(phoneNumber.match(/\d/g).length!==10){
                        //console.log('2');
                        setPhoneErrorText("Phone must be 10 digits");
                        return;
                    }
                    else if(!phoneRegex.exec(phoneNumber)){
                        //console.log('3');
                        setPhoneErrorText("Phone must be 10 digits");
                    }
                    else{
                        //console.log('4');
                        setPhoneErrorText('')
                    }
                }else{
                    //console.log('5');
                    setPhoneErrorText("Phone must be 10 digits");
                }   
            }else{ 
                
                if(!email && email === ''){
                    //console.log('6')
                    setPhoneErrorText('Phone or Email is required');
                }
            }
        }
    };

    const validateEmailInput = (emailaddress) => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(isNewName){
            if(emailaddress){
                if(!phone && phone === ''){
                    setPhoneErrorText('');
                }
                if(!emailRegex.exec(emailaddress)){
                    setEmailErrorText("Email must contain an @ symbol. Example: name@email.com");
                }else{
                    setEmailErrorText('');
                }
                
            }else if(!phone && phone === '' && !emailaddress && emailaddress === ''){
                setEmailErrorText('Phone or Email is required')
            }else{
                setEmailErrorText('');
            }
        }else{
            if(emailaddress && emailaddress !== ''){
                if(!emailRegex.exec(emailaddress)){
                    //console.log('8');
                    setEmailErrorText("Email must contain an @ symbol. Example: name@email.com");
                }else{
                    setEmailErrorText('');
                }
                
                if(!phone && phone === ''){
                    //console.log('7');
                    setPhoneErrorText('');
                }
                
            }else{
                if(!phone && phone === ''){
                    //console.log('9')
                    setEmailErrorText('Phone or Email is required');
                }else{
                    //console.log('10');
                    setEmailErrorText('');
                }
            }
        }
    };

    const canSaveRecord = () => {
        if(firstName !== '' && lastName !== '' && isValidFN && isValidLN ){
            if( 
                ((phone && phone!=='' && isValidPhone) && (!email || email ==='' || isValidEmail)) 
                || 
                (email && email!==''  && isValidEmail && (!phone || phone ==='' || isValidPhone)) 
                || 
                (phone && phone!=='' && isValidPhone && email && email !=='' && isValidEmail) 
            ){
                if(
                    ((!zip || zip==='') || (zip && isValidZip)) && 
                    ((!street || street==='') || (street && isValidStreet))  && 
                    ((!city || city==='') || (city && isValidCityLength && isValidCitySpec)) && 
                    ((props.nameToEdit.import_messages && props.nameToEdit.import_messages.state === undefined) || 
                    isValidState || !adState)
                ){
                        console.log('NamesSidePanel checkingSaveRecord can save record now');
                        return true;
                    }
                    else {
                        console.log('NamesSidePanel checkingSaveRecord started typing zip but not yet valid');
                        return false;
                    }
                }
                else {
                    console.log('NamesSidePanel checkingSaveRecord still need phone or email before save');
                    return false;

                }
            }
            else{
                console.log('NamesSidePanel checkingSaveRecord Cant save record disabling button');
                return false;
            }
        }


    function duplicateConsumerValidation() {
        const myHash = genHashMap(dublicateCheck());
        return (hashMatches.includes(myHash));
    }

    function saveConsumer() {
        console.log('NamesSidePanel saveConsumer called',getValues());
        if(duplicateConsumerValidation()){
            setErrorType('DuplicateConsumerFound');
            setTimeout(() => {
                setErrorType('');
            }, 3000);
        }
        else{
            console.log('saving ' +JSON.stringify(getValues()));
            dispatch( generateSaveNameThunk(getValues()));
            setErrorType('recordSaved');
            setTimeout(() => {
                setErrorType('');
            }, 3000);
            if(props.recordSaved){
                props.recordSaved(getValues());
            }
        }
    }

    function cancelConsumer(){
        console.log('NamesSidePanel cancelling/resetting form');
        if(props.editCancelled){
            props.editCancelled({});
            setEmailErrorText('');
            setPhoneErrorText('');
            setFirstNameErrorText('');
            setLastNameErrorText('');
        }
    }

    const handleNestSelected = (event) =>{
        console.log('NamesSidePanel handleNestSelected',event);
        setNestId(event.id);
        console.log('NamesSidePanel values select==>',getValues());
    }

    const handleKeyPress = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        // if(name === 'phone'){
        //     validatePhoneInput(value);
        //     console.log('NamesSidePanel handleKeyPress phoneErrorText isValidPhone '+phoneErrorText+ ' '+isValidPhone);
        // }else if(name === 'email'){
        //     validateEmailInput(value);
        //     console.log('NamesSidePanel handleKeyPress emailErrorText isValidEmail '+emailErrorText+ ' '+isValidEmail);
        // }
        if(event.key === 'Enter'){
            console.log('NamesSidePanel handleKeyPress: enter press here! ');            
            if(firstName!== '' && lastName!== '' && isValidFN && isValidLN){
                if((phone !=='' && isValidPhone && email ==='') || (email !=='' && isValidEmail && phone==='') || (phone !=='' && isValidPhone && email !=='' && isValidEmail)){ 
                    console.log('NamesSidePanel handleKeyPress: canSaverecord');
                    saveConsumer();
                }else{
                    console.log('NamesSidePanel handleKeyPress: phone or email still needed');
                }
            }else{
                console.log('NamesSidePanel handleKeyPress: consumer cannot be saved on Enter');
            }       
        }
    }

    return (
        <div className='addNames'>
            <div class='slds-box slds-box_xx-small slds-theme_shade'>
                <div class="slds-m-around_small">
                    {errorType !=='' ? <ToastMessages errorType={errorType} close={()=>setErrorType('')}/> : null}

                    <NestCombobox handleSelect={handleNestSelected} selectedNestId={nestId} />
                    <label className="slds-form-element__label">First Name <abbr title="required" className="slds-required">*</abbr></label>
                    <Input
                        name= "first_name"
                        value={firstName}
                        onChange={(e)=>setFirstName(e.target.value)}
                        onBlur={(e) => validateFirstNameInput(e.target.value)}
                        errorText={firstNameErrorText}
                        onKeyPress={handleKeyPress}
                        required
                    />
                    <label className="slds-form-element__label">Last Name <abbr title="required" className="slds-required">*</abbr></label>
                    <Input
                        name="last_name"
                        value={lastName}
                        onChange={(e)=>setLastName(e.target.value)}
                        onBlur={(e) => validateLastNameInput(e.target.value)}
                        errorText={lastNameErrorText}
                        onKeyPress={handleKeyPress}
                        required
                    />

                    <Input
                        label= "Phone"
                        name="phone"
                        value={phone}
                        onChange={(e)=>setPhone(e.target.value)}
			            onBlur={(e) => validatePhoneInput(e.target.value)}
                        errorText={phoneErrorText}
                        onKeyPress={handleKeyPress}
                    />
                    <p className="slds-align_absolute-center slds-p-top_x-small">OR <abbr title="required" className="slds-required">*</abbr></p>
                    <Input
                        label= "Email"
                        name="email"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
			            onBlur={(e)=>validateEmailInput(e.target.value)}
                        errorText={emailErrorText}
                        onKeyPress={handleKeyPress}
                    />
					<div className="slds-col slds-size_2-of-2">
                        <TargetSegementSelect/>
                    </div>
                    {addAddress ? <AddressSection
                        street={street} streetChange={(e)=>setStreet(e)} isStreetValid={setStreetValid} 
                        city={city} cityChange={(e)=>setCity(e)} isCityValidLength={setCityValidLength}
                        isCityValidSpec={setCityValidSpec} 
                        adState={adState} adStateChange={(e)=>setAdState(e)} isStateValid={setStateValid}
                        zip={zip} zipChange={(e)=>setZip(e)} isZipValid={setZipValid} 
                        editCancelled={cancelConsumer}/> : null}

                    <br></br>
                    <div className="slds-align_absolute-center">
                        <Button
                            className="slds-button_stretch"
                            label={buttonLabel}
                            onClick={(e) => saveConsumer()}
                            variant="brand"
                            disabled ={!canSaveRecord()}
                        />
                    </div>
                    <div className="slds-align_absolute-center">
                        <Button
                            label= "Cancel"
                            onClick={() => cancelConsumer()}
                            variant="base"
                        />
                    </div>

                    <div className="slds-align_absolute-center">
                        {addAddress ? <Button
                                        label= "Hide Address Fields"
                                        onClick={(e) => setAddAddress(false)}
                                        variant="base"
                                    /> :
                                    <Button
                                        label= "Add Address"
                                        onClick={(e) => setAddAddress(true)}
                                        variant="base"
                                    />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export function NestCombobox(props){
    let unSortedNests = useSelector(selectAllNests);

    //Sorting Nests
    unSortedNests.sort(function(a, b){
        let dateA = a.name.toLowerCase();
        let dateB = b.name.toLowerCase();
        if (dateA < dateB) 
        {
          return -1;
        }    
        else if (dateA > dateB)
        {
          return 1;
        }   
        return 0;
    });
    const nestsList = unSortedNests;
    const history = useHistory();


    const [selectedNest,setSelectedNest] = useState({"name":"None","id":"None"});

    let newNests = (nestsList?nestsList:[]).concat([
        {
            "id": "None",
            "name":"None"
        },{
            "id": "Add",
            "name":"+Add New Nest"
        }]
    );

    useEffect(()=>{
        if(props.selectedNestId!==''){
            const result = newNests.filter(x=>x.id === props.selectedNestId)
            if(result.length===1){
                setSelectedNest(result[0])
            }
        }
        else{
            setSelectedNest({"name":"None","id":"None"});
            props.handleSelect({
                id: "None"
            });
        }
    },[props])


   

    console.log('NamesSidePanel/NestCombobox: newNests',newNests);
    
	const nestsValues = newNests.map(x => {
        const disableAdd = newNests.length > 6 && x.id === 'Add' ?  true :  false;
        return {label:x.name, value: x.name, id: x.id, disabled: disableAdd};
    });

    console.log('NamesSidePanel/NestCombobox: nestsValues',nestsValues)

    const handleOnSelect = (event, data) =>{
        console.log('NamesSidePanel/NestCombobox handleOnSelect event',event,data);
        if(data.selection && data.selection.length>0 && data.selection[0].id === "Add"){
            history.push("/nests");
            return;

        }
        if(props.handleSelect){
            props.handleSelect({
                id: data.selection[0].id
            });
        }
    }
    console.log('NamesSidePanel/NestCombobox props',props);

	return (
		<IconSettings iconPath="/icons">
			<Combobox
                id='nestCombo'
                multiple={false}
                name="nestName"
                //onSelect={(event,data)=>handleOnSelect(event,data)}
			    events={{
				    onSelect: (event, data) => {
					    handleOnSelect(event,data)
					},
				}}
				labels={{
					label: 'Nest',
					placeholder: 'Select an option',
				}}
				options={nestsValues}
				value={selectedNest.name}
                hasStaticAlignment = {false}
                menuItemVisibleLength = {5}

			/>
		</IconSettings>
	);
}

export {NamesSidePanel};

